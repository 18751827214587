<template>
	<div>
	<ContentHeader title="Data Lokasi Obat"  />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Lokasi Obat</h3>
	            			<div class="col-6 text-right">
	            			</div>
	            		</div>
	            		<div class="card-body">
            				<div class="row">
            					<div class="col-lg-2"></div>
            					<div class="col-lg-8">
            						<form @submit.prevent="prosesLokasiProduk" class="mb-4">
										<div class="form-group">
											<label for="nama">Tambah Lokasi</label>
											<input type="text" class="form-control" id="nama" name="nama" autocomplete="off" v-model="lokasi.nama">
										</div>
										<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Tambah</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</button>
		            				</form>
            					</div>
            					<div class="col-lg-2"></div>
            				</div>
            				<div class="row">
            					<div class="col-lg-2"></div>
            					<div class="col-lg-8">
            						<table class="table table-bordered table-hover mt-4">
										<thead>
										<tr>
											<th scope="col" class="text-sm">NO</th>
											<th scope="col" class="text-sm">NAMA</th>
											<th scope="col" class="text-sm text-center">ACTION</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="(row, index) in data_result" :key="row.id">
											<td>{{ index + 1 }}</td>
											<td>{{ row.nama }}</td>
											<td class="d-flex justify-content-center">
												<button @click="dialogVisible = true, getDetailLokasi(row.id)" class="btn btn-sm btn-info mr-1">Edit</button>
												<button @click="konfirmasiHapus(row.id)" class="btn btn-sm btn-danger ml-1">Hapus</button>
											</td>
										</tr>
										</tbody>
									</table>
									<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
									<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>		
            					</div>
            					<div class="col-lg-2"></div>
            				</div>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>

	<el-dialog v-model="dialogVisible" title="Edit Lokasi" width="50%" :before-close="handleClose">
		<p v-if="loading_detail" class="text-center">Memuat Data</p>
		<form v-else @submit.prevent="prosesEdit">
			<div class="form-group">
				<label for="nama">Nama <span class="text-danger">*</span></label>
				<input type="text" class="form-control" id="nama" name="nama" v-model="detailLokasi.nama" autocomplete="off" required="">
			</div>
			<div class="d-flex justify-content-end">
				<button @click="dialogVisible = false" class="btn btn-secondary d-flex align-items-center mt-4 mr-2">
					<div>Batal</div>
				</button>
				<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
					<div>Submit</div>
				</button>
			</div>
		</form>
	</el-dialog>

	<LoadingScreen v-if="loading_screen" />

    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed, reactive } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingScreen from '@/components/LoadingScreen.vue'

const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const memuat_data = ref(true)
const cek_data = ref(false)
const lokasi = reactive({
	'cabang_id' : user.value.cabang_id,
	'nama' : ''
})
const loading = ref(false)
const loading_screen = ref(false)

// Edit Lokasi
const dialogVisible = ref(false)
const detailLokasi = ref(
	{
		"id": null,
		"cabang_id": null,
		"nama": null,
		"created_at": null,
		"updated_at": null
	}
)
const loading_detail = ref(false)
const getDetailLokasi = async (id) => {
	loading_detail.value = true
	await axios.get(`api/lokasi/getWhereId/${id}`)
	.then((res) => {
		detailLokasi.value = res.data
		console.log(detailLokasi.value)
	})
	.finally(() => {
		loading_detail.value = false
	})
}

const prosesEdit = async () => {
	loading_detail.value = true
	await axios.put(`api/lokasi/update/${detailLokasi.value.id}`, detailLokasi.value)
	.then((res) => {
		console.log(res)
		dialogVisible.value = false
		detailLokasi.value = {
			"id": null,
			"cabang_id": null,
			"nama": null,
			"created_at": null,
			"updated_at": null
		}
		getLokasi()
	})
	.finally(() => {
		loading_detail.value = false
	})

}
// End Edit Lokasi

const getLokasi = async () => {
	memuat_data.value = true
	cek_data.value = false
	loading_screen.value = false
	data_result.value = []
	let { data } = await axios.get(`api/lokasi/get/${user.value.cabang_id}`)

	if (data == 'kosong') {
		data_result.value = []
		memuat_data.value = false
		cek_data.value = true
	} else {
		console.log(data)
		memuat_data.value = false
		cek_data.value = false
		data_result.value = data.data
	}
}

const prosesLokasiProduk = () => {
	loading.value = true
	console.log(lokasi)
	axios.post('api/lokasi/create', lokasi)
	.then((response) => {
		Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menambahkan Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
		})	
		lokasi.nama = ''
		console.log(response)
		loading.value = false
		getLokasi()
	})
	.catch((error) => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Gagal menambahkan data',
			icon: 'error',
			showConfirmButton: false,
			timer: 1500
		})
		loading.value = false
		console.log(error)
	})
}

const konfirmasiHapus = (id) => {
Swal.fire({
	title: 'Hapus Data?',
	icon: 'warning',
	showCancelButton: true,
	confirmButtonText: `Ya`,
	cancelButtonText: `Batal`,
}).then((result) => {
	/* Read more about isConfirmed, isDenied below */
	if (result.isConfirmed) {
	hapusData(id)
	} 
})
}

const hapusData = (id) => {
loading_screen.value = true
axios.delete(`api/lokasi/destroy/${id}`)
.then((response) => {
	Swal.fire({
title: 'Berhasil!',
text: 'Berhasil Menghapus Data',
icon: 'success',
showConfirmButton: false,
timer: 1500
})
	console.log(response.data)
	getLokasi()
})
.catch((error) => {
	Swal.fire({
			title: 'Gagal!',
			text: 'Gagal menghapus data',
			icon: 'error',
showConfirmButton: false,
timer: 1500
		})
	console.log(error)
})

}

onMounted(() => {
	getLokasi()
})
</script>

<style>
	
</style>